<template>
  <div>
    <index-filter :cols="[3,3]">
      <search-filter
        slot="col_0"
        v-model="filters"
        placeholder="Search Summary"
      />
      <status-filter
        slot="col_1"
        v-model="filters"
        :only="['approved', 'rejected', 'pending']"
      />
    </index-filter>

    <v-card>
      <v-card-title>
        When more approvable types are added, this table will be useful.
      </v-card-title>
      <v-card-text class="d-flex justify-space-between">
        <div>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="paginate"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiRefresh }}
            </v-icon>
            <span>
              {{ $t('common.list.refresh') }}
            </span>
          </v-btn>

          <v-chip
            v-if="filters.approvable_id"
            class="ml-4"
            color="primary"
            outlined
          >
            Approvable ID: {{ filters.approvable_id }}
            <v-btn
              icon
              x-small
              color="primary"
              @click="reserFilterField('approvable_id')"
            >
              <v-icon size="15">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-chip>
        </div>
      </v-card-text>

      <content-request-table
        ref="tableRef"
        :table-list="tableList"
      />
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { IndexFilter, SearchFilter, StatusFilter } from '@/components'
import { useTableList } from '@/composables'
import router from '@/router'
import { mdiClose, mdiRefresh } from '@mdi/js'
import ContentRequestTable from '../components/contentRequest/ContentRequestTable.vue'
import { contentRequestService } from '../services'

export default {
  components: {
    IndexFilter,
    SearchFilter,
    StatusFilter,
    ContentRequestTable,
  },

  setup() {
    const filters = ref({
      search: null,
      status: 'pending',
      approvable_id: router.currentRoute.query.approvable_id || undefined,
    })

    const tableList = useTableList(contentRequestService, filters)

    const resetRoute = () => {
      router.replace({ query: { ...filters.value } })
      tableList.paginate()
    }

    const reserFilterField = field => {
      filters.value[field] = undefined
      resetRoute()
    }

    const {
      loading,
      tableRef,
      paginate,
    } = tableList

    return {
      filters,
      reserFilterField,

      loading,
      tableRef,
      paginate,
      tableList,

      icons: {
        mdiRefresh,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
